* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
   
}

h2 {
 font-family: 'Outfit', sans-serif;
}

body {
font-family: 'Barlow', sans-serif;
font-family: 'Outfit', sans-serif;
}
.login-box-inner-wrap h2 {
    color: #000;
    font-family: Outfit;
    font-size: 30px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -0.6px;
}
.form-set {
    display: flex;
    height: 164px;
    flex-direction: column;
    justify-content: center;
}
.login-cmn-box {
    max-width: 750px;
    margin: auto;
    border: 1px solid #b8b8b8;
    background: #1ed3b00d;
}
.login-box-inner-wrap {
    max-width: 550px;
    margin: auto;
    padding: 45px 0;
    min-height: 450px;
}
.login-box-inner-wrap form {
    margin-top: 35px;
}
.login-box-inner-wrap label {
   color: #000;
font-family: Barlow;
font-size: 15px;
font-weight: 400;
line-height: 24px; /* 160% */
letter-spacing: normal;
}
.login-box-inner-wrap input {
    border: 0.25px solid #41414357;
    background: #FFF;
    border-radius: 6px;
    height: 42px;
}
.login-box-inner-wrap input::placeholder{
color: #414143;
font-family: Barlow;
font-size: 15px;
font-weight: 400;
line-height: 18px; /* 120% */
letter-spacing: 0.3px;
}
.submit {
    margin-top: 40px!important;
    background: #1ED3B0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    text-align: center;
   font-family: Outfit;
    font-size: 15px;
    font-weight: 400;
    width: 150px;
    height: 42px;
    text-decoration: none;
    margin: auto;
}
.forgot {
    color: #000;
    text-align: center;
   font-family: Outfit;
    font-size: 15px;
    font-style: normal;
    text-decoration: none;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: normal;
    display: block;
    margin-top: 20px;
}
.login-box-inner-wrap input:focus {
    box-shadow: none;
    border: 0.25px solid #41414357;
}
.logo {
    margin-left: 90px;
}
.box-center.row {
    height: calc(100vh - 240px);
    align-items: center;
}


aside {
    border-right: 1px solid #000;
    background: #FFF;
    width: 270px;
    height: 100vh;
    position: fixed;
    overflow-y: scroll;
    left: 0;
    z-index: 1;
}
aside::-webkit-scrollbar {
    display: none;
} 
.logo-side img {
    width: 175px;
    margin: 16px 23px;
}

.side-menu a {
    color: #000;
    font-family: Outfit;
    font-size: 15px;
    padding: 10px 20px;
    font-style: normal;
    margin: 2px 15px;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    display: flex;
    align-items: center;
    
}
/* .sidebar {
    padding: 0 30px;
} */
.side-menu a:focus {
    text-decoration: none;
}
.side-menu .side-btm-space {
    margin-bottom: 20px;
}
.side-menu .side-btm-last {
    margin-top: 40px;
}

.main-wrap {
    display: flex;
}
.right-side-cmn {
    width: calc(100% - 270px);
    position: relative;
    margin-left: auto;
    height: 100vh;
}
.right-top {
    background: #316A75;
}

.heading-top h2 {
    color: #fff;
    font-family: Outfit;
    font-size: 30px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -0.6px;
    margin: 0;
}
.heading-top {
    padding: 25px 20px;
}
.right-top hr {
    color: #E9EDF7;
    margin: 1px!important;
    opacity: 1;
}

.cmn-top-fields {
    padding: 20px 7px;
}
.cmn-top-fields input {
    border: 0.25px solid #41414357;
background: #FFF;
border-radius: 6px;
font-size: 14px;
height: 40px;
padding-left: 20px;
}
.cmn-top-fields input::placeholder {
   color: #000;
font-family: Barlow;
font-size: 14px;

font-weight: 400;
line-height: 24px; /* 160% */
letter-spacing: normal;
}
.cmn-top-fields input:focus {
    box-shadow: none;
    border: 0.5px solid #D8D7CE;
}
.table-cmn tr td p {
    margin: 0;
    color: #414143;
}
.cmn-top-fields select:focus {
    box-shadow: none;
    border: 0.5px solid #D8D7CE;
}
.cmn-top-fields select {
    color: #000;
font-family: Barlow;
height: 40px;
max-width: 270px;
padding-left: 20px;
font-size: 14px;
font-style: normal;
font-weight: 400;
border: 0.25px solid #41414357;
line-height: 24px; /* 160% */
letter-spacing: normal;
border-radius: 6px;
}
.cmn-btn a {
    
        background: #1ED3B0;
    color: #FFF;
    text-align: center;
   font-family: Outfit;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 6px;
    text-decoration: none;
    padding: 12px 33px;
}
.product-tab ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
}
.side-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 170px);
}
.product-tab ul li{
    list-style: none;
    padding: 0;
}
.product-tab {
    padding: 12px 10px;
}
.product-tab ul li a {
    color: rgb(255 255 255);
   font-family: Outfit;
    font-size: 13px;
    position: relative;
    padding: 15px 0px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: normal;
    text-decoration: none;
    margin-right: 35px;
}
.product-tab .active-tab::before {
    content: "";
    position: absolute;
    bottom: 0;
    background: #1ED3B0!important;
    width: 100%;
    height: 2px;
}
.product-tab .active-tab {
    color: #1ED3B0!important;
}
.product-tab ul li a span {
    margin-left: 10px;
}
.show-results {
    color: #000;
   font-family: Outfit;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: normal;
}
.select-result span {
    color: #000;
   font-family: Outfit;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: normal;
    display: block;
    padding-right: 20px;
}
.select-result select {
    padding: 4px 10px;
    width: 55px;
    border: 0.5px solid #D8D7CE;
    background: #FFF;
    border-radius: 6px;
    color: #000;
font-family: Barlow;
font-size: 13px;
background-repeat: no-repeat;
background-position: center right 3px;
font-weight: 400;
background-image: url("../Images/Expand_down.svg");
letter-spacing: normal;
}
.select-result select:focus {
    box-shadow: none;
    border: 0.5px solid #D8D7CE;
}
.results-sec {
    padding: 10px 10px;
}
.table-cmn tr th {
    padding: 12px 12px;
        background: #316A75;
        color: #fff;
font-family: Barlow;
font-size: 13px;

font-weight: 600;
line-height: 24px; /* 160% */
letter-spacing: normal;
}
.table-cmn tr td {
    padding: 8px 12px;
    color: #000;
font-family: Barlow;
font-size: 13px;
font-weight: 500;
line-height: 24px; /* 160% */
letter-spacing: normal;
}
.table-cmn tr td a{
    color: #1ED3B0;
font-family: Barlow;
font-size: 13px;
pointer-events: auto;
font-weight: 500;
text-decoration: none;
line-height: 24px; /* 160% */
display: block;
padding: 0;
letter-spacing: normal;
}
.width-set a {
    width: 400px;
    display: block;
}
.width-set-head div {
    width: 575px;
    background: none!important;
    color: #fff!important;
}
.table-cmn tr th:first-child {
    padding-left: 23px;
}
.table-cmn tr td:first-child {
    padding-left: 23px;
}
.next-btn-fix {
    background: #fff;
    text-align: end;
    width: auto;
    padding: 8px 15px;
   box-shadow: rgb(60 64 67 / 13%) 0px 1px 2px 0px, rgb(60 64 67 / 10%) 0px 2px 6px 2px;
    margin: auto;
    border-radius: 5px;
    display: table;
    margin-top: 10px;
}
.next-btn-fix ul li a {
    color: #7d7c7c;
    font-size: 13px;
    text-transform: capitalize;
    padding: 5px 15px;
    border-radius: 4px;
    display: flex;
    font-weight: 600;
    text-decoration: none;
    align-items: center;
}
.next-btn-fix a {
    color: #7d7c7c;
    font-size: 14px;
    text-transform: capitalize;
    margin: 0 12px;
    font-weight: 600;
    text-decoration: none;
}
.next-btn-fix a:hover {
     color: #7d7c7c;
     text-decoration: none;
}
.next-btn-fix ul .next a {
    background: #e7e7e7;
}
/* .next-btn-fix a {
   text-transform: capitalize;
   font-family: Outfit;
    font-size: 15px;
    margin: 0 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: normal;
    text-decoration: none;
    padding: 12px 33px;
    background: #eeeeee;
    color: #fff;
}
.next-btn-fix a:hover {
    color: #fff;
    text-decoration: none;
} */
.back-white {
    background-color: #fff;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: end;
}
.inner-tab-cmn a {
    border: 1px solid #D9D9D9;
    background: #FFF;
    color: #40413A;
    text-align: center;
   font-family: Outfit;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    padding: 10px 25px;
    border-radius: 6px;
    margin-left: 15px;
}
.inner-tab-cmn .active {
    border: 1px solid #1ED3B0;
    background: #1ED3B0;
    color: #fff;
    border-radius: 6px;
}


.transactions-box {
    margin-top: 16px;
    border: 0.25px solid #d9d9d9;
    background: #FFF;
    padding: 20px 30px;
   
}
.transactions-box h4 {
    color: #414143;
   font-family: Outfit;
    font-size: 13px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 0;
    letter-spacing: normal;
}
.transactions-box h2 {
    color: #414143;
   font-family: Outfit;
    font-size: 24px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: normal;
    margin-top: 12px;
}
.transaction-graph img {
    width: 100%;
    height: 224px;
}
.transaction-graph {
    margin-top: 15px;
    border: 1px solid #D9D9D9;
    background: #FFF;
    box-shadow: rgb(100 100 111 / 6%) 0px 7px 29px 0px;
    padding: 13px 0px;
}
.graph-months {
    display: flex;
    justify-content: space-between;
    max-width: 340px;
    margin: auto;
}
.graph-months h5 {
    color: rgba(65, 65, 65, 0.40);
    font-family: Poppins;
    font-size: 12.171px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}
.transaction-box-wrap {
    padding: 0 10px;
}


.dashbox-inner-wrap {
    border: 1px solid #D9D9D9;
    background: #FFF;
    padding: 25px 25px;
    box-shadow: rgb(100 100 111 / 6%) 0px 7px 29px 0px;
}
.dashbox-inner-wrap h2 {
    color: #414143;
   font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: normal;
}

.dashbox-inner-wrap select.form-select {
    border: 0.5px solid #D8D7CE;
    background: #FFF;
        color: #000;
font-family: Barlow;
height: 40px;
max-width: 200px;
padding-left: 20px;
font-size: 14px;
font-style: normal;
font-weight: 400;
border: 0.25px solid #41414357;
line-height: 24px; /* 160% */
letter-spacing: normal;
border-radius: 6px;
background-image: url("../Images/Expand_down.svg");
background-repeat: no-repeat;
background-position: center right 10px;
}
.dashbox-inner-wrap select.form-select:focus {
    box-shadow: none;
}
.dash-inner-boxes {
    border: 0.25px solid #1ED3B0;
    background: #1ED3B0;
    padding: 25px 22px;
    margin-top: 40px;
    border-radius: 6px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    min-height: 160px;
}
.dash-inner-boxes h6 {
    color: #ffffff;
   font-family: Outfit;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: normal;
}
.dash-inner-boxes h4 {
    color: #ffffff;
   font-family: Outfit;
    font-size: 24px;
    font-style: normal;
    margin-top: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: normal;
}
.dash-inner-boxes p {
    color: #ffffff;
   font-family: Outfit;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    margin-top: 25px;
    margin-bottom: 0;
    line-height: 24px;
    letter-spacing: normal;
}

.dashbox-side {
    border: 1px solid #D9D9D9;
    background: #FFF;
    padding: 25px 25px;
    height: 100%;
}
.dashbox-side h2 {
    color: #414143;
   font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 56px;
    letter-spacing: normal;
}

.dashboard-items {
    margin: 30px 0 10px 10px;
}

.dash-graph img {
    width: 100%;
    height: 500px;
}
.dash-bottom {
    margin-top: 30px!important;
}
.table-cmn.table.table-sm {
    margin-bottom: 30px;
    /* height: 55vh;
    overflow-y: scroll; */
}
.side-menu a:hover {
   background: #1ed3b087;
   text-decoration: none;
  color: #316a75;
    font-weight: 600;
    border-radius: 6px;
}
.side-menu a.bar_active {
    background: #1ed3b087;
    color: #316a75;
    font-weight: 600;
    border-radius: 6px;
}


.heading-top-inner {
    display: flex;
    justify-content: space-between;
    align-items: end;
    padding: 15px 20px 15px 20px;
}
.heading-top-inner h2 {
    margin: 0;
    color: #FFF;
    font-family: Outfit;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -0.6px;
}
.heading-top-inner p {
    margin: 0;
    color: #FFF;
   font-family: Outfit;
    font-size: 13px;
    font-style: normal;
    margin-top: 3px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: normal;
}
.heading-top-inner h4 {
    color: #FFF;
    text-align: right;
   font-family: Outfit;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: normal;
    margin: 0;
}
.heading-top-inner h2 {
    margin: 0;
}

.product-tab-cmn-btns .buttons {
    border: 1px solid #6A6D60;
    background: #FFF;
    color: #40413A;
    border-radius: 6px;
   font-family: Outfit;
    font-size: 15px;
    font-weight: 400;
    text-decoration: none;
    padding: 10px 25px;
    margin-left: 15px;
}

.product-tab-cmn-btns {
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
    margin-right: 8px;
}
.product-tab-left a {
    color: #40413A;
   font-family: Outfit;
    font-size: 15px;
    font-weight: 400;
    text-decoration: none;
}
.product-tab-left a svg {
    margin-right: 10px;
}
.product-cmn-tab {
    margin: 10px 0;
}
.product-tab-cmn-btns .active {
    border: 1px solid #1ED3B0;
    background: #1ED3B0;
    color: #FFF;
    border-radius: 6px;
}
.product-cont-boxes {
    border: 0.25px solid #d9d9d9;
    background: #FFF;
    padding: 15px 20px;
}
.product-cont-boxes h6 {
    color: #414143;
   font-family: Outfit;
    font-size: 13px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: normal;
}
.product-cont-boxes h2 {
    color: #414143;
   font-family: Outfit;
    font-size: 26px;
    margin-top: 10px;
    font-weight: 300;
    line-height: 18px;
    letter-spacing: normal;
}
.product-cont-boxes {
    border: 0.25px solid #d9d9d9;
    background: #FFF;
    padding: 20px 35px;
    margin-bottom: 20px;
}

.product-graph img {
    width: 100%;
}
.product-table-top {
    border: 1px solid #D9D9D9;
    background: #FFF;
    padding: 15px 0;
    margin-top: 20px;
}
.product-table-top h3 {
    color: rgba(32, 33, 36, 0.69);
    text-align: center;
   font-family: Outfit;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: normal;
    margin: 0;
}
.table-cmn .total-amount {
    color: #3EAF3F;
    font-weight: 600;
}
.table-cmn .balance {
    color: #000;
    font-weight: 600;
}

.product-overview-box {
    border: 0.25px solid #d9d9d9;
    background: #FFF;
    padding: 30px 50px;
    margin-bottom: 30px;
    box-shadow: rgb(100 100 111 / 6%) 0px 7px 29px 0px;
}

.product-overview-box h2 {
    color: #414143;
   font-family: Outfit;
    font-size: 13px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: normal;
    margin-bottom: 30px;
}
.product-overview-bottom h3 {
    color: #414143;
   font-family: Outfit;
    font-size: 13px;
    font-weight: 700;
    line-height: 24px;
    margin: 0;
    letter-spacing: normal;
}
.product-overview-bottom p {
    color: #414143;
   font-family: Outfit;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.3px;
    margin: 0;
}
.bottom-down button {
    color: #414143;
    text-align: right;
   font-family: Outfit;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.3px;
    background: none;
    border: none;
}
.bottom-top button {
    color: #414143;
    text-align: right;
   font-family: Outfit;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.3px;
    background: none;
    border: none;
}
.product-overview-box .edit-product {
    background: #FFF;
    display: block;
    width: 100%;
    color: #1ED3B0;
    border: 1px solid #1ED3B0!important;
    text-align: center;
   font-family: Outfit;
    font-size: 13px;
    margin-top: 8px;
    border-radius: 6px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: normal;
    border: none;
    padding: 10px 0;
}
.product-overview-bottom .bottom-top {
    margin-bottom: 15px;
}
.product-overview-bottom .bottom-down {
    margin-bottom: 15px;
}
.product-cmn-color {
    background: #316A75;
}

.product-cmn-color hr {
    color: #E9EDF7;
    margin: 1px!important;
    opacity: 1;
}
.table-spacing-between {
    width: 500px;
    max-width: 100%;
}

.transact-amount h3 {
    color: #fff;
   font-family: Outfit;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin: 0;
    letter-spacing: normal;
}
.transact-amount span {
    color: #fff;
   font-family: Outfit;
    font-size: 13px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: normal;
    margin-left: 6px;
}
.product-transactions .transact-amount {
    display: flex;
}
.product-transactions {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 12px 10px;
}
.product-transactions .transact-amount:nth-child(2) {
    margin-left: 30px;
}

.heading-top-inner h2 img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 67px;
    margin-right: 10px;
}
.customer-form {
    border: 0.25px solid #d9d9d9;
    background: #FFF;
    padding: 30px 50px;
    margin-bottom: 30px;
    box-shadow: rgb(100 100 111 / 6%) 0px 7px 29px 0px;
}
.customer-form h2 {
    color: #414143;
   font-family: Outfit;
    font-size: 13px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: normal;
    margin-bottom: 25px;
}
.customer-form label {
    color: #000;
   font-family: Outfit;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: normal;
}
.customer-form input {
    border: 0.25px solid #d9d9d9;
    background: #FFF;
    padding: 10px 10px;
     color: #414143;
    font-size: 13px;
    border-radius: 6px;
}
.customer-form input::placeholder {
    color: #414143;
font-family: Barlow;
font-size: 13px;
font-weight: 400;
line-height: 18px; /* 120% */
letter-spacing: 0.3px;
}
.customer-form input:focus {
    box-shadow: none;
    border: 0.25px solid #d9d9d9;
}




.customer-form textarea {
    border: 0.25px solid #d9d9d9;
    background: #FFF;
    padding: 10px 10px;
     color: #414143;
    font-size: 13px;
    border-radius: 6px;
    resize: none;
}
.customer-form textarea::placeholder {
    color: #414143;
font-family: Barlow;
font-size: 13px;
font-weight: 400;
line-height: 18px; /* 120% */
letter-spacing: 0.3px;
}
.customer-form textarea:focus {
    box-shadow: none;
    border: 0.25px solid #d9d9d9;
}



.product-overview-box input {
    border: 0.25px solid #d9d9d9;
    background: #FFF;
    padding: 10px 10px;
     color: #414143;
    font-size: 13px;
    border-radius: 6px;
}
.product-overview-box input::placeholder {
    color: #414143;
font-family: Barlow;
font-size: 13px;
font-weight: 400;
line-height: 18px; /* 120% */
letter-spacing: 0.3px;
}
.product-overview-box input:focus {
    box-shadow: none;
    border: 0.25px solid #d9d9d9;
}



.profile-products-sec {
    border: 0.25px solid #d9d9d9;
    background: #FFF;
    padding: 30px 0;
    margin-bottom: 30px;
    box-shadow: rgb(100 100 111 / 6%) 0px 7px 29px 0px;
}
.profile-products-sec h2 {
    color: #414143;
   font-family: Outfit;
    font-size: 13px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: normal;
    margin-bottom: 25px;
    margin-left: 50px;
}
.product-inner h3 {
    color: #414143;
   font-family: Outfit;
    font-size: 13px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: normal;
    margin: 0;
}
.product-inner p {
    color: #414143;
   font-family: Outfit;
    font-size: 15px;
    font-weight: 400;
    margin-top: 5px!important;
    line-height: 18px;
    letter-spacing: 0.3px;
    margin: 0;
}


.product-inner-right button {
    color: #000;
    text-align: center;
    font-family: Outfit;
    font-size: 12px;
    font-weight: 400;
    border: none;
    padding: 4px 50px;
        background: #BFE1E9;
}
.product-inner-right p {
    color: #414143;
    text-align: right;
   font-family: Outfit;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.3px;
    margin-top: 10px;
    margin-bottom: 0;
}
.product-main-box {
    padding: 0 50px;
}
.product-inner-right {
    text-align: end;
}
.product-inner-right .closed-btn {
   background-color: #000;
   color: #fff;
   font-weight: 300;
}


.admin-box-top h3 {
    text-align: right;
   font-family: Outfit;
    font-size: 13px;
    font-weight: 700;
    color: #414143;
    line-height: 24px;
    letter-spacing: normal;
}
.admin-box-inner p {
    color: #414143;
   font-family: Outfit;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.3px;
    text-align: justify;
}
.admin-box-main {
    padding: 0 50px;
}
.admin-box-top h3 span {
    margin: 0 10px;
}

.admin-box-inner textarea {
    border: 0.25px solid #d9d9d9;
    border-radius: 6px;
    color: #414143;
    font-size: 13px;
font-weight: 400;
resize: none;
}
.admin-box-inner textarea:focus {
    box-shadow: none;
    border: 0.25px solid #d9d9d9;
}
.admin-box-inner textarea::placeholder {
    color: #414143;
font-family: Barlow;
font-size: 13px;
font-weight: 400;
line-height: 18px; /* 120% */
letter-spacing: 0.3px;
}
.admin-box-main hr {
    margin: 20px 0;
}

.product-actions {
    border: 0.25px solid #d9d9d9;
    background: #FFF;
    padding: 30px 0;
    margin-bottom: 30px;
    box-shadow: rgb(100 100 111 / 6%) 0px 7px 29px 0px;
}
.product-actions h2 {
    color: #414143;
   font-family: Outfit;
    font-size: 13px;
    padding-left: 70px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: normal;
    margin: 0;
}
.product-action-inner {
    padding: 0 50px;
}
.product-action-inner h3 {
    color: #000;
   font-family: Outfit;
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: normal;
    margin: 0;
}
.product-action-inner p {
    color: #000;
    text-align: right;
   font-family: Outfit;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.24px;
}
.product-actions .add-btn {
    margin-right: 50px;
        border: 1px solid #1ED3B0;
    background: #1ED3B0;
    color: #FFF;
   font-family: Outfit;
    font-size: 13px;
    font-weight: 400;
    border-radius: 6px;
    padding: 10px 30px;
}

.cmn-border {
 color: #E9EDF7;
    margin: 1px!important;
    opacity: 1;
}
.cmn-background {
    background: #316A75;
}


.table-cmn .total-amount-withdraw {
    color: #E14942;
    font-weight: 600;
}

.form-save-btn button {
   border: 1px solid #1ED3B0;
    background: #1ED3B0;
    color: #FFF;
   font-family: Outfit;
    padding: 11px 58px;
    font-size: 13px;
    border-radius: 6px;
    font-weight: 400;
}

   


.customer-form-new h2 {
    color: #414143;
   font-family: Outfit;
    font-size: 13px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: normal;
    margin-bottom: 25px;
    margin-top: 35px;
    padding: 0 50px;
}
.customer-form-inner {
    background: #FFF;
    padding: 30px 50px;
    margin-bottom: 30px;
}
.customer-form-new {
    border: 0.25px solid #d9d9d9;
}
.customer-form-new label {
    color: #000;
   font-family: Outfit;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: normal;
}
.customer-form-new input {
    border: 0.25px solid #d9d9d9;
    background: #FFF;
    padding: 10px 10px;
     color: #000;
    font-size: 13px;
    width: 100%;
    border-radius: 6px;
}
.customer-form-new input::placeholder {
    color: #414143;
font-family: Barlow;
font-size: 13px;
font-weight: 400;
line-height: 18px; /* 120% */
letter-spacing: 0.3px;
}
.customer-form-new input:focus {
    box-shadow: none;
    border: 0.25px solid #d9d9d9;
}
.customer-form-new select {
    border: 0.25px solid #d9d9d9;
    background: #FFF;
    padding: 10px 10px;
     color: #414143;
    font-size: 13px;
    border-radius: 6px;
    
}
.customer-form-new select:focus {
    box-shadow: none;
    border: 0.25px solid #d9d9d9;
}
.form-save-btn-new button {
    border: 1px solid #D9D9D9;
    background: #5F6368;
    color: #FFF;
   font-family: Outfit;
    padding: 8px 70px;
    font-size: 13px;
    border-radius: 6px;
    font-weight: 400;
}

.product-overview-box .edit-product-new {
    background: #fff;
    display: block;
    width: 100%;
    color: #414143;
    text-align: center;
   font-family: Outfit;
    font-size: 13px;
    margin-top: 8px;
    border-radius: 6px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: normal;
    border: none;
    padding: 10px 0;
        border: 0.25px solid #d9d9d9;

}

.member-info-inner h2 {
    margin-bottom: 10px;
}
.member-inner p {
    color: #6A6D5F;
    margin-bottom: 4px;
   font-family: Outfit;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: normal;
    font-weight: 400;
}
.address-main p {
    color: #6A6D5F;
   font-family: Outfit;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: normal;
}
.member-inner p:first-child span {
    color: #1462D7;
    font-weight: 500;
    text-decoration: underline;
    letter-spacing: normal;
}
.product-overview-box select {
    border: 0.25px solid #1ED3B0;
    border-radius: 6px;
    color: #1ED3B0;
    text-align: center;
   font-family: Outfit;
    font-size: 13px;
    font-weight: 500;
    padding: 8px;
    line-height: 24px;
    letter-spacing: normal;
    background-image: url("../Images/Expand_down.svg");
    background-size: auto;
}
.product-overview-box select:focus {
    box-shadow: none;
    border: 0.25px solid #d9d9d9;
}
.cmn-btn button {
   border: 1px solid #1ED3B0;
    background: #1ED3B0;
    color: #FFF;
    text-align: center;
   font-family: Outfit;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    border-radius: 6px;
    line-height: normal;
    text-decoration: none;
    padding: 10px 65px;
}
.product-action-inner h3 a {
    color: #1462D7;
}

.access-select-box {
    display: flex;
    justify-content: space-between;
    padding: 0 50px;
    margin-bottom: 8px;
    align-items: center;
}
.access-select-box h3 {
    color: #414143;
font-family: Barlow;
font-size: 13px;
font-weight: 700;
line-height: 24px; /* 160% */
letter-spacing: normal;
} 
.access-select-box .inner h3 {
    margin-left: 30px;
    width: 23px;
}
.access-select-box .form-check-inline {
    margin-right: 0;
     margin-left: 30px;
}
.access-select-box p {
    margin-bottom: 0;
    color: #000;
   font-family: Outfit;
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: normal;
}

.access-select-box .form-check-input {
    border-radius: 5px!important;
    border: 1px solid #979797;
    background: #FFF;
    padding: 10px 10px;
}
.access-select-box .form-check-input:checked {
    border-radius: 5px;
    border: 1px solid #979797;
  
    background: #1ED3B0;;
}
.input-image-show {
    border: 0.25px dashed #979797;
    background: #FFF;
    width: 100%;
    height: 115px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.input-image-show p {
    color: #414143;
   font-family: Outfit;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.3px;
    margin-bottom: 0;
}
.input-image-show input {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    z-index: 1;
    opacity: 0;
    top: 0;
    bottom: 0;
}

.blog-save-btn {
    text-align: center;
    margin-top: 44px;
}
.blog-save-btn button {
    padding: 12px 65px;
    border-radius: 6px;
}

.product-overview-box.blog-bottom {
    padding-bottom: 50px;
}
.check-box-inner {
    display: flex;
    margin-right: 20px;
    align-items: center;
}
.check-box-inner form {
    margin-left: 10px;
}
.check-box-inner form input{
   border-radius: 5px!important;
border: 1px solid #97979785;
background: #FFF;
width: 20px;
height: 20px;
}
.check-box-inner form input:checked {
border-radius: 5px;
    border: 1px solid #1ED3B0;
    background: #1ED3B0;
}
.create-mail-checks {
    display: flex;
    align-items: center;
}

.customer-form-inner textarea {
    border: 0.25px solid #d9d9d9;
    background: #FFF;
    padding: 10px 10px;
    color: #000;
    font-size: 13px;
    resize: none;
    border-radius: 6px;
}
.customer-form-inner textarea:focus {
    box-shadow: none;
    border: 0.25px solid #d9d9d9;
}
.dash-graph.right {
    margin-right: 12px;
}
/* validation error color */
.errors {
    color: red;
    margin-top: 3px;
    font-size: 13px;
}

.logo img {
    width: 260px;
    margin-top: 25px;
}

.delete-btn button {
    border: 1px solid #1ED3B0;
    background: #1ED3B0;
    color: #FFF;
    text-align: center;
   font-family: Outfit;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 6px;
    text-decoration: none;
    padding: 11px 33px;
}

.delete-popup .modal-header {
    border: none;
    padding: 20px 20px 0px 0px;
}

.delete-popup .modal-footer {
    border: none;
    justify-content: center;
    padding-bottom: 30px;
}


.delete-popup .modal-footer button {
    border: 1px solid #D9D9D9;
    background: #5F6368;
    color: #FFF;
    text-align: center;
   font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
      --bs-btn-active-bg: #5F6368;
          --bs-btn-active-border-color: #5F6368;
    text-decoration: none;
    padding: 10px 50px;
    border-radius: 6px;
}
  
.delete-popup .modal-body {
   
    text-align: center;
    color: #000;
    font-family: Outfit;
    font-size: 18px;
}

.delete-popup .modal-dialog {
    height: 100vh;
    align-items: center;
    display: flex;
    margin: 0 auto;
}

.delete-popup .modal-body p {
    margin: 0;
    padding-top: 20px;
}

.delete-popup .modal-content {
    border-radius: 6px;
}

.delete-popup .btn-close:focus  {
    box-shadow: none;
}


.table-cmn tr td div {
    cursor: pointer;
    display: inline-block;
    font-weight: 600;
}
.next-btn-fix ul {
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: end;
    margin: 0;
}

.next-btn-fix ul li.selected a {
    color: #ffffff!important;
    font-weight: 600;
    background: #316a75;
}
.next-btn-fix ul li.selected a:hover {
    color: #fff;
}
.date-range-picker .rs-input-group.rs-input-group-inside {
    border-radius: 6px;
    box-shadow: none;
}

.date-range-picker .rs-input-group.rs-input-group-focus {
    border-radius: 6px;
    box-shadow: none;
}
.date-range-picker .rs-input-group.rs-input-group-inside {
    cursor: pointer;
    border: none;
}
 .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
   
    background-color: #1ed3b0!important;
    
   
}
 .rs-btn-primary {
   
     background-color: #1ed3b0!important;
  
}
 .rs-calendar .rs-calendar-table-cell-selected:hover .rs-calendar-table-cell-content:hover {
    background-color: #1ed3b0;
    
}

/* 
.next-btn-fix .previous a {
    background: #e2e2e2;
    color: #316a75;
    font-weight: 500;
}
.next-btn-fix .next a {
    background: #316a75;
}
.next-btn-fix .next a:focus {
    color: #fff;
    text-decoration: none;
}
.next-btn-fix .previous a:focus {
    text-decoration: none;
} */

.side-menu a svg {
    width: 18px;
    height: 18px;
    margin-right: 10px;
}

.menu-btn.bar_active .stroke-fill path {
    stroke: #316a75;
}
.side-menu a.bar_active .path-fill path {
    fill: #316a75;
}
.side-menu a:hover .stroke-fill path {
    stroke: #316a75;
}
.side-menu a:hover .path-fill path {
    fill: #316a75;
}

.toggle-icon {
  position: fixed;
  left: 245px;
  border: 2px solid #fff;
  padding: 5px 6px;
  background: #071523;
  width: 40px;
  height: 40px;
  top: 70px;
  display: flex;
  justify-content: center;
  z-index: 1;
  border-radius: 62px;
  align-items: center;
  cursor: pointer;
}

.left-side.hide aside .side-menu a p {
  display: none;
}
.left-side.hide aside {
  width: 100px;
}
.right-side-cmn.hide {
  width: calc(100% - 100px);
  /* background: #eff8f3; */
}

.left-side.hide .toggle-icon {
  transform: rotateY(180deg);
  left: 78px;
}

.left-side.hide .side-menu a {
  justify-content: center;
}
.right-side-cmn.hide .next-btn-fix {
  width: auto;
}

.left-side.hide .side-menu a svg {
    width: 30px;
    height: 30px;
    margin: 0;
}
.left-side.hide .side-menu a {
    padding: 8px 25px;
}

.left-side img.small {
    display: none;
}

.left-side.hide img.big {
    display: none;
}
.left-side.hide img.small {
    display: block;
    width: 52px;
}


.forgot:hover {
    color: #000;
    text-decoration: none;
}

.dash-graph .recharts-wrapper {
    width: 100%!important;
    height: 500px!important;
}
.dash-graph {
    border: 1px solid #D9D9D9;
    background: #FFF;
    box-shadow: rgb(100 100 111 / 6%) 0px 7px 29px 0px;
}

.dash-graph h3 {
    color: #414143;
   font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-transform: uppercase;
    letter-spacing: normal;
    margin-top: 22px;
    margin-left: 42px;
}

.cmn-btn a:hover {
    color: #fff;
    text-decoration: none;
}

.image-upload-main img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    object-fit: contain;
}

a:hover {
    text-decoration: none!important;
}
.product-tab-left a:hover {
color: #000;
}



.customer-form-inner .upload-img-show {
    width: 100%;
    height: 200px;
    object-fit: contain;
}

.rich-text {
    margin-bottom: 25px;
   
}

.rich-text .rdw-editor-toolbar {
    border: none;
}
.rdw-dropdown-selectedtext {
    color: #000;
}
.rdw-dropdown-selectedtext:hover {
    color: #000;
}
.preview p {
    word-wrap: break-word;
}
.rich-text .rdw-editor-main {
    padding: 10px 10px;
    height: 350px;
}
.preview {
    width: 100%;
    padding: 5px 10px;
}

.table-responsive.main {
    height: 60vh;
}

.transaction-graph .recharts-wrapper {
    width: 100%!important;
}

.delete-popup.upload .image-upload-main {
    text-align: left;
}
.delete-popup.upload .image-upload-main label {
    font-size: 17px;
}
.delete-popup.upload button.btn.btn-primary {
    border: 1px solid #D9D9D9;
    background: #5F6368;
    color: #FFF;
    text-align: center;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    --bs-btn-active-bg: #5F6368;
    --bs-btn-active-border-color: #5F6368;
    text-decoration: none;
    padding: 10px 50px;
    border-radius: 6px;
}
.delete-popup.upload .modal-body {
    padding: 10px 43px 35px 43px;
}
.delete-popup.upload .input-image-show p {
    padding: 0;
}
.delete-popup.upload .image-upload-main input {
    border: 0.25px solid #b8b5b5;
    background: #FFF;
    padding: 10px 10px;
    color: #414143;
    font-size: 13px;
    border-radius: 6px;
}
.delete-popup.upload .image-upload-main input:focus {
    border: 0.25px solid #b8b5b5;
    box-shadow: none;
   
}

.product-action-inner a {
    color: #1ed3b0;
    font-weight: 400;
}


.customer-form .form-select:focus {
    box-shadow: none;
    border: 1px solid #dee2e6;
}
.customer-form select {
    font-size: 14px;
    padding: 9px 14px;
}
.product-images {
    display: flex;
    gap: 10px;
}
.product-images img {
    width: 115px;
    height: 100px;
    object-fit: cover;
        border: 0.25px solid #d9d9d9;
}


.product-check {
    border-radius: 4px;
    display: flex;
    gap: 13px;
}
.product-check .form-check input {
    margin-top: 4px;
    width: 15px;
    height: 15px;
}
.product-check .form-check-label {
    color: #6A6D60;
    font-size: 13px;
    font-weight: 400;
    font-family: 'Barlow', sans-serif;
}

.product-check .form-check input:checked {
    background-color: #1ed3b0;
    border-color: #1ed3b0;
}

.create-product-images img.upload-img-show {
    width: 200px;
    height: 200px;
    object-fit: cover;
}
.create-product-images {
    gap: 10px;
}


.product-btn {
    background: none;
}
.product-btn img {
    width: 20px;
}

.add-value-btn {
    border: 1px solid #dee2e6;
    width: 100%;
    padding: 8px;
    border-radius: 6px;
    margin-top: 5px;
    background: #fff;
}
.add-option {
    background: none;
    margin-top: 23px;
}
.add-option img {
    margin-right: 12px;
}
.accordion-button:not(.collapsed) {
    background-color: #fff !important;
    border-top: 1px solid #dee2e6!important;
}
.accordion-button:focus {

    box-shadow: none!important;
}

.customer-form-inner input:focus {
    box-shadow: none;
    border: 1px solid #dee2e6;
}

.accordion-button {
    border-top: 1px solid #dee2e6!important;
}

.custom-text {
    font-size: 16px;
}

.customer-form-inner.product {
    padding: 0 40px;
}
.customer-form-inner.product h2 {
    color: #414143;
    font-family: Outfit;
    font-size: 13px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: normal;
    margin-bottom: 25px;
    margin-top: 35px;
    
}

.customer-form-inner.product h2 {
    padding: 0;
    text-transform: uppercase;
}

.accord-heading {
    display: flex;
    gap: 10px;
}
.accord-options ul {
    display: flex;
    gap: 10px;
    padding: 0;
    list-style: none;
}
.accord-options ul li {
    background: #1ed3b0;
    padding: 2px 15px;
    border-radius: 10px;
    font-weight: 300;
    color: #fff;
}

.customer-form-inner.variants {
    padding: 0;
}

.custom-text {
    font-family: Outfit;
    font-size: 16px;
    padding: 24px 0px;
    text-transform: capitalize;
}


.right-side-cmn button.accordion-button::after {
   position: absolute;
   right: 20px;
       z-index: 1111;
    pointer-events: auto;
}

table.table-cmn.listing.table button.accordion-button.collapsed {
    padding: 0;
}
 .table-responsive.main.listing button.accordion-button {
    padding: 0;
    pointer-events: none;
}
table.table-cmn.listing.table {
    margin: 0;
}
table.table-cmn.listing.table .accordion-item {
    border: none!important;
}

.table-responsive.main.listing .accordion-body {
    padding: 0!important;
}
table.table-cmn.listing.table tr {
    border-bottom: 1px solid #dee2e6;
}

.accordion-header tr td {
    font-weight: 600;
    font-size: 14px;
}

button.accordion-button.collapsed {
    background: white;
}

.table-cmn.table.table-sm ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
}
.table-cmn.table.table-sm ul li {
    padding: 12px 12px;
    background: #316A75;
    color: #fff;
    font-family: Barlow;
    font-size: 13px;
    font-weight: 600;
    line-height: 24px;
    width: 100%;
    margin: 0;
    letter-spacing: normal;
}

.width-set {
    width: 150px;
}

.listing-tab-header.accordion a {
    background: transparent;
    color: #fff;
}
.listing-tab-header .accordion-button::after {
    display: none;
}
.listing-tab-header .accordion-item {
    border: none;
}
.width-set img {
    width: 25px;
    padding: 0!important;
    transform: rotateX(180deg);
}

.side-menu .badge {
    color: #ffffff;
    background: #316a75;
    padding: 5px 10px;
    margin-left: 8px;
    font-size: 12px;
    border-radius: 5px;
}


::placeholder {
    color: #a9a9a9 !important;
}